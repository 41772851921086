import { createSlice } from '@reduxjs/toolkit';

const initState = {
    history: false
}

export const pageSlice = createSlice({
    name: 'page',
    initialState: initState,
    reducers: {
        setHistory: (state, action) => {
            state.history = action.payload
        }
    }
})

export const { setHistory } = pageSlice.actions;
export default pageSlice.reducer;