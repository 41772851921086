import { useContext, createContext } from "react";
import { useState } from "react";

const UpdateContext = createContext(null);

export function UpdateProvider({children}) {
    
    const [update, setUpdate] = useState(0);
    return(
    <UpdateContext.Provider
        value={{
            update,
            setUpdate,
        }}
        >
            {children}
        </UpdateContext.Provider>
    );
}

export function useUpdate() {
    const context = useContext(UpdateContext);
    if (!context) {
      throw new Error("Missing Update context");
    }
  
    return context;
}