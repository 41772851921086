import { ConnectButton, WalletButton } from '@rainbow-me/rainbowkit';
import Logo from '../assets/logo.png';

export function Header() {
    return (
        <div className="flex w-full justify-between py-[8px] lg:px-[20px] px-[8px] bg-comp-bg-color rounded-[10px] border-[1px] border-border">
            <div className="flex gap-[16px]">
                <div className="text-white text-[16px] font-[700]">
                    <img src={Logo} className='lg:w-[100px] lg:h-auto w-auto h-[30px]' alt='' />
                </div>
            </div>
            <div className='flex'>
                {/* <button className="w-full bg-button border-[1px] border-button-border h-[32px] rounded-[5px] text-white text-[12px] font-[600] px-[20px]">Connet Wallet</button> */}
                {/* <ConnectButton /> */}
                <ConnectButton.Custom>
                    {({
                        account,
                        chain,
                        openAccountModal,
                        openChainModal,
                        openConnectModal,
                        authenticationStatus,
                        mounted,
                    }) => {
                        // Note: If your app doesn't use authentication, you
                        // can remove all 'authenticationStatus' checks
                        const ready = mounted && authenticationStatus !== 'loading';
                        const connected =
                            ready &&
                            account &&
                            chain &&
                            (!authenticationStatus ||
                                authenticationStatus === 'authenticated');

                        return (
                            <div
                                {...(!ready && {
                                    'aria-hidden': true,
                                    'style': {
                                        display: 'flex',
                                        opacity: 0,
                                        pointerEvents: 'none',
                                        userSelect: 'none',
                                    },
                                })}
                            >
                                {(() => {
                                    if (!connected) {
                                        return (
                                            <button
                                                className="w-full bg-button border-[1px] border-button-border h-[32px] rounded-[5px] text-white text-[12px] font-[600] px-[20px]"
                                                onClick={openConnectModal}
                                                type="button"
                                            >
                                                Connect Wallet
                                            </button>
                                        );
                                    }

                                    if (chain.unsupported) {
                                        return (
                                            <button
                                                className="w-full bg-button border-[1px] border-button-border h-[32px] rounded-[5px] text-white text-[12px] font-[600] px-[20px]"
                                                onClick={openChainModal}
                                                type="button"
                                            >
                                                Wrong network
                                            </button>
                                        );
                                    }

                                    return (
                                        <div style={{ display: 'flex', gap: 12 }}>
                                            <button
                                                className="w-fit bg-button border-[1px] border-button-border h-[32px] rounded-[5px] text-white text-[12px] font-[600] px-[20px]"
                                                onClick={openChainModal}
                                                style={{ display: 'flex', alignItems: 'center' }}
                                                type="button"
                                            >
                                                {chain.hasIcon && (
                                                    <div
                                                        style={{
                                                            background: chain.iconBackground,
                                                            width: 12,
                                                            height: 12,
                                                            borderRadius: 999,
                                                            overflow: 'hidden',
                                                            marginRight: 4,
                                                        }}
                                                    >
                                                        {chain.iconUrl && (
                                                            <img
                                                                alt={chain.name ?? 'Chain icon'}
                                                                src={chain.iconUrl}
                                                                style={{ width: 12, height: 12 }}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {chain.name}
                                            </button>

                                            <button
                                                className="w-full bg-button border-[1px] border-button-border h-[32px] rounded-[5px] text-white text-[12px] font-[600] px-[20px]"
                                                onClick={openAccountModal}
                                                type="button"
                                            >
                                                <span>{account.displayName}</span>
                                                <span>{account.displayBalance
                                                    ? ` (${account.displayBalance})`
                                                    : ''}</span>
                                            </button>
                                        </div>
                                    );
                                })()}
                            </div>
                        );
                    }}
                </ConnectButton.Custom>
            </div>
        </div>
    )
}