import Raffles from '../assets/raffles.svg';

export function Panel() {
    return (
        <>
            <div className="flex flex-col flex-1 border-[1px] border-border justify-between px-[20px] py-[20px] bg-comp-bg-color rounded-[7px]">
                <div className="flex gap-[16px]">
                    <div className="">
                        <img src={Raffles} className='w-[25px] h-[25px]' alt=''/>
                    </div>
                    <div className="text-white text-[14px] font-[600]">
                        Raffles
                    </div>
                </div>
                <div className="pt-[14px] border-t-[1px] border-line">
                    <div className='relative text-description text-[10px] font-[400]'>
                        Disclaimer:  Betting/Gambling can be high addictive. Only spend what you can afford and are willing to lose. YOU ARE 100% RESPONSIBLE for your own bets and Cash Grab Daily accepts NO LIABILITY for any loss incured.

                    </div>
                </div>
            </div>
        </>
    )
}