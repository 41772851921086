export function formatNumber(amount, decimalsShown = 2) {
    if (typeof amount === "string") {
        amount = parseFloat(amount);
    }
    return amount.toFixed(decimalsShown);
}

export function formatAddress(address) {
    const len = address.length;
    return address.substr(0, 5) + "..." + address.substr(len - 3, 3);
}

export function twoDigit(number) {
    var twodigit = number >= 10 ? number : "0"+number.toString();
    return twodigit;
}

export function formatTime(timestamp) {
    const time = new Date(timestamp * 1000);
    return time.getFullYear() + "-" + twoDigit(time.getMonth() + 1) + "-" + twoDigit(time.getDate()) + " " + twoDigit(time.getHours()) + ":" + twoDigit(time.getMinutes()) + ":" + twoDigit(time.getSeconds());
}