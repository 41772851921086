import { useState, useEffect } from "react"
import {getTicketPrice, buyTicket} from "../api/lottery";
import {useConnect, useAccount} from "wagmi";
import {formatNumber} from "../utils";
import {useUpdate} from "../hook/useUpdate";
import { toast } from "react-toastify";

export function BuyTicket() {

    const {update, setUpdate} = useUpdate();
    const { chainId } = useConnect();
    const { address, isConnected } = useAccount();
    const [status, setStatus] = useState(false);
    const [numOfTiket, setNumOfTickets] = useState(0);
    const [ticketPrice, setTicketPrice] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const getFetchData = async() => {
        const price = await getTicketPrice();
        setTicketPrice(formatNumber(price, 3));
    }
    function onAmount(val) {
        if (val == "") {
            setNumOfTickets(0);
            return;
        }
        var pattern = /^[0-9]\d*(?:\.\d{0,8})?$/;
        if (!pattern.test(val))
            return;
        setNumOfTickets(Math.round(val));
    }
    useEffect(() => {
        if (isConnected)
            getFetchData();
    }, [chainId, address]);

    useEffect(() => {
        setTotalAmount(formatNumber(ticketPrice * numOfTiket, 3));
    }, [ticketPrice, numOfTiket]);

    const onBuyTicket = async() => {
        
        if (totalAmount == 0) {
            toast.dismiss();
            toast.info("Ticket Number cannot be zero", {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            return;
        }

        setStatus(true);
        const res = await buyTicket(numOfTiket, totalAmount);
        if (res) {
            const next = update + 1;
            setUpdate(next);
        }
        
        setStatus(false);
    }

    return (
        <>
            <div className="lg:flex grid grid-cols-2 gap-[15px] mt-[10px] lg:mt-0">
                <div className="flex flex-col gap-[7px] lg:w-[288px]">
                    <p className="text-white text-[12px] font-[500]">Ticket Price</p>
                    <div className='relative flex w-full'>
                        <span className="bg-comp-bg-color w-full border-[1px] border-border rounded-[3px] text-[12px] font-[700] h-[40px] text-white px-[15px] py-[10px]">
                            {ticketPrice}
                        </span>
                        <div className="absolute right-[10px] bottom-[10px] ">
                            <div className="flex gap-[12px] items-center">
                                <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png" className="w-[20px] h-[20px]" alt="" />
                                <span className="text-label-color font-[600] text-[12px]">ETH</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-[7px] lg:w-[152px]">
                    <p className="text-white text-[12px] font-[500]">Number of tickets</p>
                    <div className='relative'>
                        <input
                            type="text"
                            inputmode="numeric" pattern="[0-9]*"
                            className='bg-comp-bg-color border-[1px] border-border rounded-[3px] w-full text-[12px] font-[700] h-[40px] text-white px-[15px] py-[10px]'
                            value={numOfTiket}
                            onChange={e=>onAmount(e.target.value)}
                            step={1}
                        />
                    </div>
                </div>
                <div className="flex lg:flex-col gap-[7px] lg:w-[122px] lg:px-[20px]">
                    <p className="text-white text-[12px] font-[500]">Total</p>
                    <div className="flex text-white text-[12px] font-[700] items-center align-middle h-full">{totalAmount} ETH</div>
                </div>
                <div className="lg:flex lg:flex-col lg:flex-1 lg:justify-end col-span-2">
                    <button 
                        className="w-full bg-button border-[1px] border-button-border h-[40px] rounded-[5px] text-white text-[14px] font-[700]"
                        onClick={onBuyTicket}
                        disabled={status}
                    >
                        Buy Ticket(s)
                    </button>
                </div>
            </div>
        </>
    )
}