import { useSelector } from 'react-redux';
import { Header, Panel, History } from "../components"

export const BaseLayout = (props) => {
    const { children } = props;
    const { history } = useSelector(state => state.page)

    return (
        <div className="relative bg-bg-color overflow-hidden lg:h-[100vh] min-h-[100vh]">
            <div className={`flex flex-col w-full h-full px-[20px] pt-[10px] pb-[25px] bg-bg-color`}>
                <Header />
                <div className={`flex h-full lg:mt-[10px] lg:flex-row flex-col`}>
                    <div className="lg:flex w-[245px] h-full hidden">
                        <Panel />
                    </div>
                    <main className="flex w-full lg:w-auto mx-auto main">{children}</main>
                    <div className={'lg:flex lg:w-[400px] h-full lg:flex-row flex-col mt-[7px] lg:mt-0' + ( history ? ' flex' : ' hidden')}>
                        <History />
                    </div>

                </div>
            </div>
        </div>
    )
}
