import { useState , useEffect} from "react";
import {useConnect, useAccount} from "wagmi";
import {formatEther} from "viem";
import {getPlayerList, getTicketPrice, getMyHistory} from "../api/lottery";
import {useUpdate} from "../hook/useUpdate";

const currentColumns = [
    { label: 'Ticket Number', key: 'ticketNumber', align: 'left' },
    { label: 'Price', key: 'price', align: 'left' },
    { label: 'Winning Chance', key: 'winningChance', align: 'left' }
];
const historyColumns = [
    { label: 'Pool Number', key: 'poolNumber', align: 'left', mobile: true },
    { label: 'Result', key: 'result', align: 'left', mobile: true },
    { label: 'Win Amount', key: 'winAmount', align: 'left', mobile: true }
]

const tempHistoryTableData = [
    { ticketNumber: '12345', price: '0.1', result: 'Won!', winAmount: '5', winAmountUsd: '$12,000' },
    { ticketNumber: '54321', price: '0.1', result: 'Not Winner', winAmount: '', winAmountUsd: '$12,000' },
    { ticketNumber: '34521', price: '0.1', result: 'Not Winner', winAmount: '', winAmountUsd: '$12,000' }

]

export function TicketBoard() {

    const {update} = useUpdate();
    const { chainId } = useConnect()
    const { address, isConnected } = useAccount();
    const [currentStatus, setCurrentStatus] = useState(0);
    const [columns, setColumns] = useState(currentColumns);

    const [tableData, setTableData] = useState([]);
    const [currentTicket, setCurrentTicket] = useState([]);
    const [historyTicket, setHistoryTicket] = useState([]);

    const fetchCurrentTicketData = async(price) => {
        let data = [];
        const res = await getPlayerList();
        if (res.length != 0) {
            const player_len = res.length;
            for(let index = 0; index < player_len; index ++) {
                if (address != res[index])
                    continue;
                data.push({
                    ticketNumber: (index + 1).toString(),
                    price,
                    winningChance: Math.round(100 / player_len)
                });
            }
            setCurrentTicket(data);
            if (currentStatus == 0) {
                setTableData(data);
            }
        } else {
            setTableData([]);
            setCurrentTicket([]);
        }
    }

    const fetchMyHistoryData = async() => {

        let data = [];
        const res = await getMyHistory(address);
        if (res.length != 0) {
            let index;
            for(index = 0; index < res[0].length; index ++) {
                const amount = formatEther(res[0][index]);
                if (0 != amount) {
                    data.push({
                        poolNumber: index + 1,
                        winAmount: amount,
                        winAmountUsd: amount * 2250,
                        result: parseInt(res[1][index])
                    })
                }
            }   
            setHistoryTicket(data);
            if (currentStatus == 1) {
                setTableData(data);
            }

        } else {
            setTableData([]);
            setHistoryTicket([]);
        }
    }

    const fetchHistoryData = async() => {
        if (currentStatus == 1) {
            setTableData([]);
        }
    }

    useEffect(() => {
        const func = async() =>{
            const price = await getTicketPrice();
            fetchCurrentTicketData(price);
            fetchMyHistoryData();
            fetchHistoryData();
        }
        if (address != "" && isConnected) 
            func();
    }, [chainId, address, update]);

    return (
        <>
            <div className='flex flex-col flex-1 border-[2px] rounded-[5px] border-line w-full'>
                <div className="flex py-[10px] px-[20px] w-full gap-[16px]">
                    <button
                        className={"px-[24px] text-[12px] py-[5px] text-white font-[500] rounded-[7px] border-[1px] border-line" + (!currentStatus ? ' bg-[#00A01A3B]' : ' bg-transparent')}
                        onClick={() => {
                            setCurrentStatus(0)
                            setTableData(currentTicket)
                            setColumns(currentColumns)
                        }}
                    >
                        My Current Tickets
                    </button>
                    <button
                        className={"px-[24px] text-[12px] py-[5px] text-white font-[500] rounded-[7px] border-[1px] border-line" + (currentStatus ? ' bg-[#00A01A3B]' : ' bg-transparent')}
                        onClick={() => {
                            setCurrentStatus(1)
                            //const tmpTableData = window.innerHeight > 1024 ? historyTicket : historyTicket.filter(item => !item.mobile)
                            setTableData(historyTicket)
                            //const tempColumns = window.innerWidth > 1024 ? historyColumns : historyColumns.filter(item => item.mobile)
                            setColumns(historyColumns)
                        }}
                    >
                        My History
                    </button>
                </div>
                <div className="relative w-full h-full min-h-[375px]" style={{overflowY:"auto", maxHeight:"370px"}}>
                    <table
                        className="w-full max-h-full"
                    >
                        <thead className='w-full'>
                            <tr className="bg-[#FFFFFF0F] text-header-label text-[12px]">
                                {
                                    columns.map(item => <th key={item.key} className={`px-[20px] font-[500] py-[5px] text-${item.align}`}>{item.label}</th>)
                                }

                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData.map((item, index) => <tr className='even:bg-[#FFFFFF03] odd:bg-[#FFFFFF05]' key={index}>
                                    {
                                        !currentStatus ? <>
                                            <td className='text-white text-[12px] py-[8px] px-[20px] text-left'>
                                                {item.ticketNumber}
                                            </td>
                                            <td className='text-white text-[12px] py-[8px] px-[20px] text-left'>{item.price} ETH</td>
                                            <td className='text-white text-[12px] py-[8px] px-[20px] text-left'>{item.winningChance} %</td>
                                        </> : 
                                        <>
                                            <td className='text-white text-[12px] py-[5px] px-[20px] text-left'>
                                                {item.poolNumber}
                                            </td>
                                            <td className={'text-[12px] py-[5px] px-[20px] text-left' + (item.result ? ' text-win-color' : ' text-not-winer-color')}>{item.result ? "Won!" : "Not Win"}</td>
                                            <td className='text-white text-[12px] py-[5px] px-[20px] text-left'>
                                                {item.result ? item.winAmount : 0} ETH
                                                {item.winAmount && <span className="text-green">{item.result  ? ` ($ ${item.winAmountUsd})` : ``}</span>}
                                            </td>
                                        </>
                                    }
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div >
        </>
    )
}