import { useDispatch, useSelector } from 'react-redux'
import { ArrowLeftIcon } from '@heroicons/react/16/solid';
import { Announcement, BuyTicket, TicketBoard } from '../components';
import { ToastContainer } from "react-toastify";
import { setHistory } from '../redux/page'
import 'react-toastify/dist/ReactToastify.css';
export function Home() {

    const { history } = useSelector(state => state.page)
    const dispatch = useDispatch();
    return (
        <>
            <div className="toast-container"><ToastContainer limit={2}/></div>
            <div className='flex w-full flex-1 lg:mt-[21px] mt-[7px]'>
                <div className='flex flex-col w-full lg:min-w-[776px] lg:gap-[35px] gap-[7px] h-full'>
                    <div className='flex justify-end lg:hidden'>
                        <button
                            className="flex px-[20px] py-[10px] bg-transparent border-[1px] border-line h-[40px] rounded-[5px] text-white text-[12px] font-[500] items-center align-middle"
                            onClick={() => dispatch(setHistory(!history))}
                        >
                            {!history && 'Raffle History'}
                            {history && <> <ArrowLeftIcon className='w-4 h-4' /><span className='align-middle items-center'> Back</span></>}
                        </button>
                    </div>
                    {
                        (window.innerWidth > 1024 || !history) && <>
                            <Announcement />
                            <div className='flex flex-col gap-[22px] flex-1'>
                                <BuyTicket />
                                <TicketBoard />
                            </div>
                        </>
                    }

                </div>
            </div>
        </>
    )
}