import LotteryABI from "../assets/LotteryABI.json"
import { toast } from "react-toastify";
import { mainnet, sepolia } from 'viem/chains'
import {
    formatEther, 
    parseEther, 
    createPublicClient, 
    http, 
    createWalletClient, 
    custom
} from "viem"

function getSelectedChain() {
    const mode = process.env.REACT_APP_PRODUCTION_MODE;
    const res = mode == true ? mainnet : sepolia;
    return res;
}

const publicClient = createPublicClient({
    batch:{
        multicall: true,
    },
    chain: getSelectedChain(),
    transport: http()
});


function getContractAddress() {
    const address = process.env.REACT_APP_LOTTERY_ADDRESS;
    return address??"0x5800bA927CD793EB09b7595b299A61693d63851F";
}

export async function getTicketPrice() {

    const data = await publicClient.readContract({
        address: getContractAddress(),
        abi: LotteryABI,
        functionName: "ticketPrice"
    });
    return formatEther(data);
}

export async function getPoolInfo() {
    
    try{
        const wagmiContract = {
            address: getContractAddress(),
            abi: LotteryABI
        };

        const res = await publicClient.multicall({
            contracts:[
                {
                    ...wagmiContract,
                    functionName: "lotteryId"
                },
                {
                    ...wagmiContract,
                    functionName: "lotteryPeriod"
                }
            ]
        });
        const id = parseInt(res[0].result);
        const period = parseInt(res[1].result);

        const info = await publicClient.readContract({
            address: getContractAddress(),
            abi: LotteryABI,
            functionName: "getLotteryInfo",
            args: [id]
        });
        
        let totalAmount = 0;
        let remain = 0;
        if (id > 0) {
            totalAmount = (parseFloat(formatEther(info.price)) * parseInt(info.numOfTickets));
            remain = parseInt(info.startTime) + period - Date.now() / 1000;
        }
        
        return {
            id: id,
            startTime: id == 0 ? 0 : parseInt(info.startTime),
            amount: totalAmount,
            remain: remain < 0 ? 0 : remain
        };
    } catch(e) {
        console.log(e);
        return {
            id: 0,
            startTime: 0,
            amount: 0,
            remain: 0
        };

    }
}

export async function getPlayerList() {

    const wagmiContract = {
        address: getContractAddress(),
        abi: LotteryABI
    };

    const info = await publicClient.readContract({
        ...wagmiContract,
        functionName: "getPlayers"
    });
    
    return info;

}

export async function getPoolHistory() {
   
    const wagmiContract = {
        address: getContractAddress(),
        abi: LotteryABI
    };

    const info = await publicClient.readContract({
        ...wagmiContract,
        functionName: "getWinLotteryList"
    });

    return info;
}

export async function getMyHistory(account) {

    const wagmiContract = {
        address: getContractAddress(),
        abi: LotteryABI
    };

    const info = await publicClient.readContract({
        ...wagmiContract,
        functionName: "getPlayerHistory",
        args: [account]
    });

    return info;
}

export async function buyTicket(counts, amount) {

    try{
        const walletClient = createWalletClient({
            chain: getSelectedChain(),
            transport: custom(window.ethereum)
        });

        const account = await walletClient.getAddresses();

        const wagmiContract = {
            account: account[0],
            address: getContractAddress(),
            abi: LotteryABI
        };

        const { request } = await publicClient.simulateContract({
            ...wagmiContract,
            functionName: 'buyTicket',
            args: [counts],
            value: parseEther(amount)
        })

        const hash = await walletClient.writeContract(request);

        toast.dismiss();
        toast.info(<div>Buy Ticket</div>, {
          position: "bottom-center",
          autoClose: 60000,
        });

        const transaction = await publicClient.waitForTransactionReceipt( 
            { hash }
        );

        toast.dismiss();
        toast.success(
          <div>
            "Success Transaction"
            <br />
            <a
            href={`https://sepolia.etherscan.io/tx/${hash}`}
            target="_blank"
            rel="noreferrer"
            >
            View in block explorer
            </a>
          </div>,
          { position: "bottom-center", autoClose: 10000 }
        );
        console.log("transaction", transaction);
        return true;
    } catch(e) {
        console.log("e", e);
        toast.dismiss();
        toast.info("Lottery is closed", {
          position: "bottom-center",
          autoClose: 60000,
        });
    }
    return false;

}