import { Provider } from 'react-redux';
import './App.css';
import '@rainbow-me/rainbowkit/styles.css';
import { BaseRoute } from './routes';
import { BaseLayout } from './layouts/base.layout';
import store from './redux';
import {
  mainnet
} from 'wagmi/chains';
import { WagmiConfig, configureChains, createConfig, sepolia} from 'wagmi';
import {goerli} from '@wagmi/core/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import {UpdateProvider} from "./hook/useUpdate";
import {RefreshContextProvider} from "./hook/useRefresh";

import { RainbowKitProvider, lightTheme, getDefaultWallets, darkTheme } from '@rainbow-me/rainbowkit';


function App() {

  const { chains, publicClient } = configureChains(
    [mainnet, sepolia, goerli],
    [
      alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_ID }),
      // jsonRpcProvider({
      //   rpc: (chain) => {
      //     return { http: chain.rpcUrls.default.http[0] };
      //   },
      // }),
      publicProvider()
    ]
  );

  const { connectors } = getDefaultWallets({
    appName: 'My RainbowKit App',
    projectId: 'YOUR_PROJECT_ID',
    chains
  });

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
  })

  return (
    <Provider store={store}>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains} theme={darkTheme()}>
          <UpdateProvider>
            <RefreshContextProvider>
              <BaseLayout>
                <BaseRoute />
              </BaseLayout>
            </RefreshContextProvider>
          </UpdateProvider>
        </RainbowKitProvider>
      </WagmiConfig>

    </Provider>

  );
}

export default App;
