import { useState, useEffect, useMemo } from "react"
import LogoSVGRight from '../assets/logo_1.svg';
import LogoSVGLeft from '../assets/logo_2.svg';
import { getPoolInfo } from "../api/lottery";
import {formatNumber, twoDigit} from "../utils";
import {useConnect, useAccount} from "wagmi";
import {useUpdate} from "../hook/useUpdate";
import {useRefresh} from "../hook/useRefresh";

export function Announcement() {

    const {update, setUpdate} = useUpdate();
    const { chainId } = useConnect()
    const { address, isConnected } = useAccount();
    const {slow, fast} = useRefresh();
    const [totalPoolAmount, setTotalPoolAmount] = useState(0);
    const [totalRemainTime, setTotalRemainTime] = useState(0);
    const [announcement, setAnnouncement] = useState("00 H:00 M: 00 S");

    const getFetchData = async() => {
        const info = await getPoolInfo();
        if (totalRemainTime < info.remain) {
            setUpdate(update + 1);
        }
        const amount = info.amount;
        setTotalPoolAmount(formatNumber(amount, 3));
        setTotalRemainTime(info.remain);
    }

    useEffect(() => {
        if (isConnected)
            getFetchData();
    }, [chainId, address, update, slow]);

    useEffect(() => {
        let time = totalRemainTime;

        if (totalRemainTime > 0) {

            setTotalRemainTime(time - 1);
            const hours = Math.floor(time / 3600);
            time %= 3600;
            const minutes = Math.floor(time / 60);
            const seconds = Math.floor(time % 60);
            const strTime = `${twoDigit(hours)} H: ${twoDigit(minutes)} M: ${twoDigit(seconds)} S`;

            setAnnouncement(strTime);
        } else {
            setAnnouncement("Picking Winner");
        }
    }, [fast]);

    return (
        <>
            <div className="flex flex-col items-center justify-center py-[40px] gap-[10px] align-middle bg-gradient-to-l from-[#1F2024] via-[#1F202400] to-[#1F2024] rounded-[7px]">

                <div className="flex gap-[95px] items-center align-middle">
                    <img src={LogoSVGRight} className='w-[70px] h-[70px] hidden lg:block' alt='' />
                    <div className='flex flex-col items-center'>
                        <p className="text-label-color text-[14px] font-[400] text-center">Total Raffle Pool</p>
                        <div className='relative items-center text-center lg:w-[253px] w-[221px] bg-comp-bg-color-1 border-[1px] border-border-1 rounded-[5px] py-[15px] mt-[6px]'>
                            <p className='text-white font-[600] text-[18px] text-center'>{totalPoolAmount} ETH</p>
                            <p className='text-green font-[600] text-[14px] text-center'>${formatNumber(totalPoolAmount * 2250)}</p>
                        </div>
                        <div className='flex items-center justify-center gap-[20px] mt-[35px]'>
                            <img src={LogoSVGRight} className='w-[32px] h-[32px] block lg:hidden' alt='' />

                            <div className='flex flex-col text-center'>
                                <p className='text-label-color'>Winner Announcement</p>
                                <div className='text-white text-[15px]'>
                                    <span>{announcement}</span>
                                </div>
                            </div>
                            <img src={LogoSVGLeft} className='w-[32px] h-[32px] block lg:hidden' alt='' />

                        </div>

                    </div>
                    <img src={LogoSVGLeft} className='w-[70px] h-[70px] hidden lg:block' alt='' />

                </div>
            </div>
        </>
    )
}