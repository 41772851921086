import { useState, useEffect } from "react"
import {useConnect, useAccount} from "wagmi";
import {formatEther} from "viem"
import {getPoolHistory} from "../api/lottery";
import {useUpdate} from "../hook/useUpdate";
import {formatNumber, formatAddress, formatTime} from "../utils";

export function History() {

    const [data, setData] = useState([]);
    const { chainId } = useConnect();
    const { address, isConnected } = useAccount();
    const {update} = useUpdate();

    const fetchInfo = async() => {

        const res = await getPoolHistory();
        let temp = [];
        
        for(let index = res.length - 1; index >= 0; index--) {
            if (res[index].timestamp != 0) 
            {
                // const timediff = (Date.now() /1000) - parseInt(res[index].timestamp);
                temp.push({
                    amount: formatEther(res[index].amount),
                    player: res[index].player,
                    ticketNum: parseInt(res[index].ticketNum),
                    timestamp: formatTime(parseInt(res[index].timestamp))
                });
            }
        }

        setData(temp);

    }

    useEffect(() => {
        if (isConnected)
            fetchInfo();
    }, [chainId, address, update]);
    return (
        <>
            <div className="flex flex-col w-full flex-1 bg-comp-bg-color border-[1px] border-border rounded-[7px]">
                <div className="text-[12px] text-white text-center py-[8px]">
                    Raffle History
                </div>
                <div className="flex w-full min-h-[343px]">
                    <table className="w-full h-fit">
                        <thead>
                            <tr className="bg-white bg-opacity-5">
                                <th className="text-header-label text-[12px] font-[500] text-left py-[5px] px-[15px]">Time</th>
                                <th className="text-header-label text-[12px] font-[500] text-left py-[5px] px-[15px]">Winner</th>
                                <th className="text-header-label text-[12px] font-[500] text-right py-[5px] px-[15px]">Win Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((item, index) => <tr className='even:bg-[#FFFFFF03] odd:bg-[#FFFFFF05]' key={index}>
                                    <td className='text-white text-[12px] py-[5px] px-[15px] text-left'>{item.timestamp}</td>
                                    <td className='text-white text-[12px] py-[5px] px-[15px] text-left'>{formatAddress(item.player)}</td>
                                    <td className='text-right text-[12px] py-[5px] px-[15px]'>
                                        <span className='text-white'>{`${item.amount} ETH`}</span>
                                        <span className='text-green'>{` ($ ${formatNumber(item.amount * 2500, 2)})`}</span>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}