import { configureStore } from '@reduxjs/toolkit';
import pageReducer from './page';

const store = configureStore({
    reducer: {
        page: pageReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
})

export default store;